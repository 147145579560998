import React, { FC, useCallback } from 'react'
import ChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left'
import Button from '@veneer/core/dist/scripts/button'
import { useBillingFormState, useDispatch, useGetText } from '../../hooks'
import { StyledBackButton, StyledButtonContent } from './styles'
import { popCurrentStepAction } from '../../actions'
import { BillingFlowSteps } from '../../types'

export const BackButton: FC = () => {
  const getText = useGetText()
  const dispatch = useDispatch()
  const onBack = useCallback(() => dispatch(popCurrentStepAction()), [dispatch])
  const { STEP_ONE } = BillingFlowSteps
  const { billingFlowStepHistory } = useBillingFormState()
  const currentStep = billingFlowStepHistory[billingFlowStepHistory.length - 1]

  if (currentStep === STEP_ONE) {
    return null
  }

  return (
    <StyledBackButton>
      <Button
        data-testid="previous-screen-button"
        data-analyticsid="previous-screen-button"
        appearance="ghost"
        onClick={onBack}
      >
        <StyledButtonContent>
          <ChevronLeft color="hpBlue7" size={32} />
          <span>{getText('billing_address.previous_screen')}</span>
        </StyledButtonContent>
      </Button>
    </StyledBackButton>
  )
}
