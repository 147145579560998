import React from 'react'
import { BaseURLProvider, Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { BillingFormProvider } from './billingFormProvider'
import { BillingFormContainer } from './billingFormContainer'
import { ErrorType, DataType, PaymentState } from '../../types'
import { MockFixtures } from '../../mock/lib/mockFixtures'
import '@veneer/styles/typography/typography.css'
import { GlobalStyle } from '../../styles/styles'
import { SpinnerWithoutText } from '../Spinner'

export type BillingFormProps = {
  /**
   * PayPal account identifier
   */
  accountIdentifier?: string

  /** Country */
  country: string

  /** Language */
  language: string

  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider: JarvisAuthProvider

  /**
   * enum of (dev,pie,stage,prod) used for stratus web calls
   */
  stack: Stack

  /**
   * function to get the API base URLs
   *
   * - apiName and apiVersion can be used to query Services Discovery API
   */
  baseURLProvider?: BaseURLProvider

  /**
   * xCorrelationId used for logging
   */
  xCorrelationId?: string

  /**
   * mock flag, if set to true then all Billing API calls will be mocked
   */
  mockStratus?: boolean

  /**
   * mock fixture object, used to override the default mock fixtures
   */
  mockFixtures?: MockFixtures

  /**
   * true if Jarvis Billing is running inside a webview, false otherwise
   */
  nativeApp?: boolean

  /**
   * true if PayPal should be enabled while inside a webview, false otherwise
   * */
  enablePaypalInNativeApp?: boolean

  /**
   * true if we want to show the 2CO virtual keyboard
   */
  virtualKeyboard?: boolean

  /**
   * function called when billing data is updated, if an error parameter is present then the save failed.
   *
   * Possible error values:
   *
   * - expired\_critical\_scope
   * - expired\_token
   */
  onSave?: (error?: ErrorType) => void

  /**
   * function called when save button is clicked. Used for SMB analytics, receives button click event
   */
  onSaveButtonClick?: (event?: unknown) => void

  /**
   * function called when dataType is successfully updated, not when an error occurs.
   *
   * Possible dataType values:
   *
   * - accountType
   * - billingAddress
   */
  onUpdate?: (dataType?: DataType) => void

  /**
   * function called when two checkout status is updated
   *
   * Possible status values:
   *
   * - loading
   * - success
   * - failed
   * - canceled
   */
  on2CoStatusUpdate?: (paymentState?: PaymentState) => void

  /**
   * function called when user cancels
   */
  onCancel?: () => void

  /**
   * function called to track click events, used for analytics
   */
  trackClickEvent?: (linkId: string, linkPlacement: string) => void

  /**
   * enable GPay when all payment layout is enabled
   */
  enableGPay?: boolean

  /**
   * enable the new all payment layout
   */
  enableAllPaymentLayout?: boolean
}

/**
 * React component that renders the billing form.
 */
export const BillingForm = (props: BillingFormProps) => {
  if (!props.authProvider) return <SpinnerWithoutText />

  return (
    <>
      <GlobalStyle />
      <BillingFormProvider {...props}>
        <BillingFormContainer />
      </BillingFormProvider>
    </>
  )
}

BillingForm.defaultProps = {
  onSave: () => null,
  onSaveButtonClick: () => null,
  onUpdate: () => null,
  onCancel: () => null,
  on2CoStatusUpdate: () => null,
  trackClickEvent: () => null,
  enableGPay: false,
  enableAllPaymentLayout: false
}
