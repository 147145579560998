import React, { FC, useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import { PayPalCheckStatusContent } from './payPalCheckStatusContent'
import { PayPalSuccessContent } from './payPalSuccessContent'
import { useBillingFormState, useDispatch } from '../../hooks'
import {
  createPhcEventStatusAction,
  fetchBillingInfoAction,
  fetchPhcEventStatusAction
} from '../../actions'

type PayPalConfirmationModalProps = {
  show: boolean
  closeModal: () => void
  showError: () => void
}
export const PayPalConfirmationModal: FC<PayPalConfirmationModalProps> = ({
  show,
  closeModal,
  showError
}) => {
  const dispatch = useDispatch()
  const [checkStatusButtonLoading, setCheckStatusButtonLoading] =
    useState(false)
  const [showFinishReminder, setShowFinishReminder] = useState(false)
  const { phcEventStatus } = useBillingFormState()
  const success =
    phcEventStatus != null &&
    ['success', 'successNoPaymentChanged'].includes(phcEventStatus)

  const checkPhcEventStatus = async (close: boolean) => {
    if (checkStatusButtonLoading) return

    setShowFinishReminder(false)
    setCheckStatusButtonLoading(true)
    try {
      const phcEvent = await dispatch(fetchPhcEventStatusAction())
      if (['success', 'successNoPaymentChanged'].includes(phcEvent.status)) {
        await dispatch(fetchBillingInfoAction())
      } else if (phcEvent.status === 'waiting' && !close) {
        setShowFinishReminder(true)
      } else {
        // create new phc event order id
        await dispatch(createPhcEventStatusAction())
        showError()
        closeModal()
      }
    } catch {
      showError()
      closeModal()
    } finally {
      setCheckStatusButtonLoading(false)
    }
  }

  if (!show) return null

  return (
    <Modal
      data-testid="paypal-confirmation-modal"
      show={show}
      closeButton={!success}
      closeOnBlur={false}
      onClose={async () => await checkPhcEventStatus(true)}
    >
      {success ? (
        <PayPalSuccessContent closeModal={closeModal} />
      ) : (
        <PayPalCheckStatusContent
          loading={checkStatusButtonLoading}
          onClick={async () => await checkPhcEventStatus(false)}
          showFinishReminder={showFinishReminder}
        />
      )}
    </Modal>
  )
}
