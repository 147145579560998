import React from 'react'
import projectNames from '../src/configs/projectNames'
import { BillingForm, BillingFormProps } from '../src'

export default function Root(props: { properties: BillingFormProps }) {
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <BillingForm {...props.properties} />
    </section>
  )
}
