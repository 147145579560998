import React, { useEffect, useState, FC } from 'react'
import Checkbox from '@veneer/core/dist/scripts/checkbox'
import {
  useBillingAddressError,
  useBillingAddress,
  useDispatch,
  useGetText,
  useShippingAddress
} from '../../hooks'
import {
  setBillingAddressAction,
  validateBillingAddressAction
} from '../../actions'
import { AddressForm } from './addressForm'
import { getRequiredFields } from '../Helpers/helpers'
import {
  StyledAddressPreviewCard,
  StyledFullName,
  StyledShippingCardDetail,
  StyledShippingCard,
  StyledShippingInfo,
  StyledSubtitle
} from './styles'

type AddressSectionProps = {
  showStateDropdown: boolean
}

export const AddressSection: FC<AddressSectionProps> = ({
  showStateDropdown
}) => {
  const getText = useGetText()
  const dispatch = useDispatch()
  const shippingAddress = useShippingAddress()
  const billingAddress = useBillingAddress()
  const billingAddressError = useBillingAddressError()
  const [shippingChecked, setShippingChecked] = useState(false)
  const [showShippingCheckbox, setShowShippingCheckbox] = useState(false)
  const hasShippingAddress = Boolean(shippingAddress?.street1)
  const hasBillingAddress =
    billingAddress &&
    Object.values(billingAddress).filter((value) => !!value).length > 1

  useEffect(() => {
    if (
      hasShippingAddress &&
      !hasBillingAddress &&
      !billingAddressError &&
      !showShippingCheckbox
    ) {
      setShowShippingCheckbox(true)
      setShippingChecked(true)
    }
  }, [
    hasShippingAddress,
    hasBillingAddress,
    billingAddressError,
    showShippingCheckbox
  ])

  useEffect(() => {
    if (shippingChecked && hasShippingAddress) {
      ;(async () => {
        try {
          await dispatch(setBillingAddressAction(shippingAddress))
        } catch {
          // do nothing
        }
      })()
    }
  }, [shippingChecked, shippingAddress, hasShippingAddress, dispatch])

  useEffect(() => {
    if (shippingChecked) {
      ;(async () => {
        try {
          await dispatch(
            validateBillingAddressAction(getRequiredFields(showStateDropdown))
          )
        } catch {
          // do nothing
        }
      })()
    }
  }, [dispatch, shippingChecked, showStateDropdown])

  return (
    <form>
      <StyledShippingInfo>
        <div>
          <StyledSubtitle>{getText('billing_address.title')}</StyledSubtitle>
          {showShippingCheckbox ? (
            <div id="use-shipping-address" data-testid="use-shipping-address">
              <Checkbox
                id="use-shipping-address"
                data-analyticsid="use-shipping-address"
                name="useShippingAddress"
                checked={shippingChecked}
                onChange={() => setShippingChecked(!shippingChecked)}
                label={getText('billing_address.use_shipping')}
              />
            </div>
          ) : null}
        </div>
        {shippingChecked && (
          <StyledAddressPreviewCard
            appearance="outlined"
            data-testid="address-preview-card"
            content={
              <StyledShippingCard>
                <StyledFullName>
                  {shippingAddress?.firstName} {shippingAddress?.lastName}
                </StyledFullName>
                {shippingAddress?.company && (
                  <StyledShippingCardDetail>
                    {shippingAddress.company}
                  </StyledShippingCardDetail>
                )}
                <StyledShippingCardDetail>
                  {shippingAddress?.street1}
                </StyledShippingCardDetail>
                {shippingAddress?.street2 && (
                  <StyledShippingCardDetail>
                    {shippingAddress.street2}
                  </StyledShippingCardDetail>
                )}
                <StyledShippingCardDetail>
                  {shippingAddress?.city}, {shippingAddress?.state}{' '}
                  {shippingAddress?.zipCode}
                </StyledShippingCardDetail>
                <StyledShippingCardDetail>
                  {shippingAddress?.countryCode}
                </StyledShippingCardDetail>
              </StyledShippingCard>
            }
          />
        )}
      </StyledShippingInfo>
      {!shippingChecked && (
        <AddressForm
          showStateDropdown={showStateDropdown}
          shippingChecked={shippingChecked}
        />
      )}
    </form>
  )
}
