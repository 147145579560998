import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const PaymentButtons = styled.div`
  && {
    display: flex;
    text-align: center;
    margin: 16px 0;
    width: 100%;
    justify-content: space-between;
  }
`

export const OrSection = styled.div`
  && {
    display: flex;
    text-align: center;
    margin: 16px 0;
    width: 100%;

    span {
      text-transform: uppercase;
      line-height: 20px;
      font-size: 14px;
    }
  }
`

export const OrBar = styled.div`
  && {
    background: ${tokens.color.gray3};
    height: 2px;
    width: 100%;
    margin: 8px;
  }
`
