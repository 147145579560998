import React, { useState, FC } from 'react'
import { useBillingInfo, useGetText, useSettings } from '../../hooks'
import { Spinner } from '../Spinner'
import * as Styled from './styles'
import { PayPalLogo } from './payPalLogo'
import WarningIcon from '@veneer/core/dist/scripts/icons/icon_warning'
import { PayPalButton } from './payPalButton'

type AccountMessageProps = {
  showError?: boolean
}

const AccountMessage: FC<AccountMessageProps> = ({ showError }) => {
  const getText = useGetText()
  const email = useBillingInfo()?.payPal?.email
  const { enableBillingUpdatedLayout } = useSettings() || {}
  let result: JSX.Element

  if (showError) {
    result = enableBillingUpdatedLayout ? (
      <div>
        <WarningIcon size={48} />
        <PayPalLogo data-testid="pay-pal-icon" marginBottom={12} />
        <Styled.PayPalInlineErrorMessage
          dangerouslySetInnerHTML={{
            __html: getText(
              'billing_type_selector.pay_pal.pay_pal_error_message'
            )
          }}
        ></Styled.PayPalInlineErrorMessage>
      </div>
    ) : (
      <Styled.PayPalErrorMessage>
        {getText(
          'billing_type_selector.pay_pal.pay_pal_error_message_legacy_design'
        )}
      </Styled.PayPalErrorMessage>
    )
  } else if (email) {
    result = (
      <>
        <Styled.PayPalInfo>
          <PayPalLogo data-testid="pay-pal-icon" marginBottom={12} />
          <strong>{email}</strong>
        </Styled.PayPalInfo>
        <p>{getText('billing_type_selector.pay_pal.pay_pal_account')}</p>
      </>
    )
  } else {
    result = (
      <Styled.PayPalInfo>
        {enableBillingUpdatedLayout && (
          <PayPalLogo data-testid="pay-pal-icon" marginBottom={12} />
        )}
        <p>{getText('billing_type_selector.pay_pal.no_pay_pal_account')}</p>
      </Styled.PayPalInfo>
    )
  }
  return result
}

export const PayPalContent = () => {
  const getText = useGetText()
  const { locale } = useBillingInfo()?.payPal || {}
  const { enableBillingUpdatedLayout } = useSettings() || {}
  const [showContent, setShowContent] = useState(false)
  const [showError, setShowError] = useState(false)

  const localeLink = () =>
    ((
      {
        it_CH: 'it_IT',
        en_PR: 'en_US',
        de_AT: 'de_DE',
        fr_BE: 'fr_FR',
        nl_BE: 'nl_NL',
        de_CH: 'de_DE',
        fr_CH: 'fr_FR'
      }[locale as string] || locale
    )
      ?.split('_')[1]
      ?.toLowerCase())

  return (
    <Styled.PayPalContent>
      {showContent || showError ? (
        <>
          <AccountMessage showError={showError} />
          {!(showError && enableBillingUpdatedLayout) && (
            <Styled.PayPalWhatIs
              href={`//www.paypal.com/${localeLink()}/webapps/mpp/paypal-popup`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getText('billing_type_selector.pay_pal.what_is')}
            </Styled.PayPalWhatIs>
          )}
        </>
      ) : (
        <Spinner />
      )}
      <div id="pay-pal-button">
        <PayPalButton
          data-testid="paypal-button"
          onButtonReady={() => setShowContent(true)}
          onShowError={() => setShowError(true)}
        />
      </div>
    </Styled.PayPalContent>
  )
}
